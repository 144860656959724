import 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import 'intersection-observer'
import SiteHeader from './modules/site-header'
import { Card, Share, Embeds, TimeAgo } from './components'

const components = [
    new SiteHeader(),
    new Card(),
    new Share(),
    new Embeds(),
    new TimeAgo()
]

components.forEach(component => component.mount())
