import { $, $$ } from './../helpers/qsa'

export default class {

    constructor() {
        this.root = $('.site-header-wrap')
    }

    mount() {
        // Scroll to current item if any
        const currentItem = $('.current-menu-item', this.root)
        currentItem && currentItem.scrollIntoView({ block: 'nearest', inline: 'center' })

        // Show/hide on scroll
        this.scrollTick = false
        this.lastScrollPos = 0

        window.addEventListener('scroll', this.handleScroll.bind(this))
    }

    handleScroll() {
        if (this.scrollTick) {
            return
        }

        window.requestAnimationFrame(() => {
            const { scrollY } = window

            this.setScrollStatus(scrollY)

            this.lastScrollPos = scrollY
            this.scrollTick = false
        })

        this.scrollTick = true
    }

    setScrollStatus(scrollY) {
        this.root.classList.toggle('site-header-wrap--up', scrollY > 80 && scrollY > this.lastScrollPos)
    }
}
