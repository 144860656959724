import { $, $$ } from './../helpers/qsa'

export default class {

    mount() {
        const shares = $$('.js-share')

        if (navigator.share) {
            shares.forEach(el => {
                el.removeAttribute('hidden')
                el.addEventListener('click', this.handleShare.bind(this))
            })
        }
    }

    handleShare(e) {
        e.stopPropagation()

        const title = e.currentTarget.getAttribute('data-share-title')
        const url = e.currentTarget.getAttribute('data-share-url')

        navigator.share({
            title: title,
            url: url
        })
    }

}
