import { $$ } from '../helpers/qsa'

export default class {

    mount() {
        const cards = $$('.article[data-href]')

        cards.forEach(card => {
            $$('.article__header img', card).forEach(el => {
                el.addEventListener('click', this.handleCardImageClick.bind(this))
            })
        })
    }

    handleCardImageClick(e) {
        const card = e.target.closest('.article')

        if (card.hasAttribute('data-href')) {
            window.location.href = card.getAttribute('data-href')
        }
    }

}
