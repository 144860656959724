import { $$ } from '../helpers/qsa'
import { formatDistanceToNowStrict, differenceInSeconds } from 'date-fns'

const now = new Date()

export default class {

    mount() {
        $$('.js-timeago').forEach(el => {
            const date = new Date(el.getAttribute('datetime'))
            const diffInSeconds = differenceInSeconds(now, date)

            // Only show difference less than 13 hours ago
            if (diffInSeconds < 46800) {
                el.textContent = formatDistanceToNowStrict(date, { addSuffix: true })
            } else {
                el.hidden = true
            }
        })
    }

}
